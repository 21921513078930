/* eslint-disable react/jsx-props-no-spreading */
import { getLinkHref } from 'lib/utils/helpers';
import OptionalWrapper from './optional-wrapper';
import SiteLink from './sitelink';

const OptionalSiteLinkWrapper = ({ link, children, onClick, className = '', id }) => (
  <OptionalWrapper
    condition={link}
    wrapper={wrapperChildren => (
      <SiteLink
        className={className}
        href={getLinkHref(link.link)}
        external={link.externalLink}
        onClick={onClick}
        id={id}
      >
        {wrapperChildren}
      </SiteLink>
    )}
  >
    {children}
  </OptionalWrapper>
);

export default OptionalSiteLinkWrapper;
