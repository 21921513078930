import Link from 'next/link';
import { isArray, isObject } from 'underscore';
import { trackInWindow, trackEvents } from 'hooks/tracking/track';

const wrapInSlashes = string => (string === '/' ? string : `/${string.replace(/^\/+|\/+$/g, '')}/`);

/**
 * @param {Array|Object} query
 * @returns {String}
 */
const toQueryString = query => {
  if (isArray(query)) return `?${query.join('&')}`;
  if (isObject(query)) return `?${new URLSearchParams(query).toString()}`;
  return '';
};

const SiteLink = ({
  href,
  className,
  external,
  onClick,
  onMouseEnter,
  children,
  query,
  id = '',
  scroll = true,
  disabled = false,
}) => {
  const trackLinkClick = () => {
    if (onClick) onClick();

    if (external) {
      trackInWindow({
        event: trackEvents.outboundLinkClick,
        link: href ?? external ?? '',
      });
    }
  };

  if (!external && !href) {
    return (
      <button
        className={className}
        onClick={trackLinkClick}
        onKeyDown={trackLinkClick}
        onMouseEnter={onMouseEnter}
        type="button"
        tabIndex={0}
        id={id}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  if (external) {
    return (
      <a
        href={external}
        className={className}
        onClick={trackLinkClick}
        onMouseEnter={onMouseEnter}
        target="_blank"
        rel="noreferrer"
        id={id}
      >
        {children}
      </a>
    );
  }

  const linkHref =
    typeof href === 'object' ? href : `${wrapInSlashes(href)}${toQueryString(query)}`;

  return (
    <Link scroll={scroll} passHref href={linkHref}>
      <a
        href="passHref"
        className={className}
        onClick={trackLinkClick}
        onKeyDown={trackLinkClick}
        onMouseEnter={onMouseEnter}
        role="button"
        tabIndex={0}
        id={id}
      >
        {children}
      </a>
    </Link>
  );
};
export default SiteLink;
